@import 'src/styles/variables';

@import './ita-material.scss';

@import '../../node_modules/cropperjs/dist/cropper.css';

@font-face {
    font-family: OpenSans;
    font-style: normal;
    font-weight: $font-weight--regular;
    src: url('../assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: OpenSans;
    font-style: normal;
    font-weight: $font-weight--semi-bold;
    src: url('../assets/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: OpenSans;
    font-style: normal;
    font-weight: $font-weight--bold;
    src: url('../assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

body {
    background: white;
    font-family: OpenSans, sans-serif;
    font-style: normal;
    font-weight: $font-weight--regular;
    margin: 0px;
}

li {
    list-style-type: none;
}

.container-padding {
    padding: 27px 30px 30px;
}

.example-margin {
    margin: 0 10px;
}

.relative {
    position: relative;
    width: 100%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.hidden {
    visibility: hidden;
}

.hide-element {
    display: none !important;
}

.hidden-force {
    visibility: hidden;
    height: 0;
    width: 0;
    border: 0;
    padding: 0;
}

:host ::ng-deep .mat-menu-content {
  height: 40px;
  display: flex;
}

.pointer {
    cursor: pointer;
}

.c-move {
  cursor: move;
}

a {
    cursor: pointer;
}

textarea {
    resize: none;
}

.ita-input {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #E9EFF4;
    padding-left: 16px;
    padding-right: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 27px;

    &:hover {
        cursor: pointer;
    }
}

.ita-disabled {
    color: $disabled;
}

.ita-input-container {
    width: 100%;
    height: 60px;
}

.ita-textarea {
    width: 100%;
    min-height: 135px;
    border-radius: 8px;
    border: 1px solid #E9EFF4;
    padding-top: 20px;
    padding-left: 18px;
    padding-right: 12px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 17px;

    &:hover {
        cursor: pointer;
    }
}

.ita-placeholder {
    width: max-content;
    position: absolute;
    top: -5px;
    left: 16px;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.1625px;
    color: $gray-500;
    background: white;
}

.ita-validation {
    margin-top: 2px;
    font-size: 11px;
    line-height: 12px;
    color: $error;
}

.ita-big {
    .ita-input {
        height: 75px;
        font-size: 16px;
    }

    .ita-placeholder {
        padding: 3px 2px;
        font-size: 17px;
    }

    .ita-validation {
        margin-top: 8px;
        font-size: 11px;
    }

    .ita-input-container {
        height: 110px;
    }
}

.ita-default {
    .ita-input, .ita-validation {
        width: 227px;
    }
}

.ita-div {
    padding-top: 13px;
}

.ita-toggle {
    font-size: 14px;
    line-height: 27px;
}

.right-section-padding {
    padding-top: 20px;
}

.chip {
    background: #e9eff4;
    display: flex;
    border-radius: 15px;
    padding: 8px 14px;
    color: $gray-600;

    &__right {
        margin-left: 5px;
    }
}

.ita-btn {
    min-width: 143px;
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 19px;
    color: $white;
    border: 1px solid #A0232B;

    &:hover {
        cursor: pointer;
    }
}

.upper_case {
    text-transform: uppercase
}

.ita-btn-red {
  @extend .ita-btn;
  background: linear-gradient(128.8deg, #A0232B -6.65%, #D51F2A 98.61%);
}

.ita-btn-green {
    @extend .ita-btn;
    background: linear-gradient(128.8deg, #008E44 100%, #01C070 100%);
}

.ita-btn-white {
  @extend .ita-btn;
  background: #F6F9FB;
  color: $gray-600;
  border: 1px solid #808FA3;

  &:hover {
      background: #E9EFF4;
      border: 1px solid rgba(128, 143, 163, 0.4);
      border-radius: 2px;
  }
}

.ita-btn-option {
    @extend .ita-btn;
    background: #FFFFFF;
    border: 1px solid #A0232B;
    border-radius: 2px;
    color: $error;

    &:hover {
        background: #A0232B;
        border: 1px solid #A0232B;
        border-radius: 2px;
        color: $white;
    }
}

.page-save-container {
    margin-top: 43px;
    margin-bottom: 64px;
}

.page-save {
    margin-right: 35px;
}

.page-decline {
    margin-right: 35px;
}

.subtitle-1 {
    font-weight: $font-weight--semi-bold;
    font-size: 13px;
    line-height: 24px;
    color: $gray-700;
}

.subtitle-2 {
    font-weight: $font-weight--semi-bold;
    font-size: 13px;
    line-height: 24px;
    color: $gray-700;
}

.paragraph-1 {
    font-size: 15px;
    line-height: 20px;
    color: $gray-700;
}

.paragraph-2 {
    font-size: 13px;
    line-height: 18px;
    color: $gray-700;
}

.caption-1 {
    font-size: 12px;
    line-height: 16px;
    color: $gray-700;
}

.caption-2 {
    font-weight: $font-weight--semi-bold;
    font-size: 12px;
    line-height: 16px;
    color: $gray-700;
}

label, .star {
    font-weight: $font-weight--bold;
    font-size: 13px;
    line-height: 16px;
    color: $gray-1100;
}


.h1 {
    font-size: 36px;
    line-height: 48px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}

.h2 {
    font-size: 30px;
    line-height: 40px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}


.h3 {
    font-size: 30px;
    line-height: 40px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}


.h4 {
    font-size: 26px;
    line-height: 32px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}

.h5 {
    font-size: 22px;
    line-height: 32px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}

.h6 {
    font-size: 18px;
    line-height: 24px;
    color: $gray-700;
    font-weight: $font-weight--bold;
}

.header-margin {
    margin-top: 40px;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.top-15 {
    margin-top: 15px;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-top-32 {
    margin-top: 32px;
}

.padding-r-20 {
    padding-right: 20px;
}

.padding-r-16 {
    padding-right: 16px;
}

.right-side-content {
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: none;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0px 0px 19px -5px rgb(150 159 165 / 60%) inset;
  background: $white;
}

.ita-small .ita-input {
  height: 24px;
}

.ita-small .mat-form-field-infix {
  padding-top: 0px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $gray-500;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: $gray-500;
    background-color: $gray-200;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled):not(.mat-focused) .mat-form-field-flex:hover .mat-form-field-outline {
    background-color: $gray-300;
    border-radius: 4px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    background-color: transparent;
    box-shadow: inset 0 2px 2px #EDF1F7, 0 0 0 3px #EDF1F7;
    border-radius: 4px;
}

.ita-small .mat-select-arrow-wrapper {
  top: -3px;
  width: 24px;
  height: 24px;
}

.ita-small .mat-form-field-label {
  top: 7px;
  height: 20px;
}

.ita-small .clear {
  top: 5px !important;
}

.ita-small .c-datepicker .mat-form-field-infix {
  transform: translateY(-3px);
}

.ita-small .c-datepicker .mat-form-field-suffix {
  width: 25px;
  height: 25px;
}

.ita-small .c-datepicker .mat-icon-button {
  width: 25px;
  height: 25px;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.mat-checkbox-inner-container {
    width: 20px !important;
    height: 20px !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mr-8 {
    margin-right: 8px;
}
.ml-8 {
    margin-left: 8px;
}

.mat-input-element:disabled {
    color: $gray-1100;

}
