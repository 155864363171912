@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/pink-bluegrey.css";
@import 'src/styles/variables';
@import '@angular/material/theming';

$custom-typography: mat.define-typography-config($font-family: 'OpenSans, sans-serif');
@include mat.core($custom-typography);
//
$mat-myapp-accent: (
    50 : #F4E5E6,
    100 : #E3BDBF,
    200 : #D09195,
    300 : #BD656B,
    400 : #AE444B,
    500 : #A0232B,
    600 : #981F26,
    700 : #8E1A20,
    800 : #84151A,
    900 : #730C10,
    A100 : #FFA5A8,
    A200 : #FF7276,
    A400 : #FF3F45,
    A700 : #FF252C,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-primary: (
    50 : #E0EEFA,
    100 : $primary-blue-100,
    200 : $primary-blue-200,
    300 : $primary-blue-300,
    400 : $primary-blue-400,
    500 : $primary-blue-500,
    600 : $primary-blue-600,
    700 : $primary-blue-700,
    800 : $primary-blue-800,
    900 : $primary-blue-900,
    A100 : #DFE8FF,
    A200 : #ACC3FF,
    A400 : #799FFF,
    A700 : #5F8DFF,
    contrast: (
        50 : #FFFFFF,
        100 : #FFFFFF,
        200 : #FFFFFF,
        300 : #FFFFFF,
        400 : #FFFFFF,
        500 : #FFFFFF,
        600 : #FFFFFF,
        700 : #FFFFFF,
        800 : #FFFFFF,
        900 : #FFFFFF,
        A100 : #FFFFFF,
        A200 : #FFFFFF,
        A400 : #FFFFFF,
        A700 : #FFFFFF,
    )
);

$mat-myapp-warn: (
    50 : #FFF4E0,
    100 : #FFE3B3,
    200 : #FFD080,
    300 : #FFBD4D,
    400 : #FFAE26,
    500 : #EB455A,
    600 : #FF9800,
    700 : #FF8E00,
    800 : #FF8400,
    900 : #FF7300,
    A100 : #FFFFFF,
    A200 : #FFF7F2,
    A400 : #FFD8BF,
    A700 : #FFC9A6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$primary: mat.define-palette($mat-myapp-primary);
$accent: mat.define-palette($mat-myapp-accent);
$warn: mat.define-palette($mat-myapp-warn);

$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

mat-card {
    padding: 0px !important;

    mat-card-title, mat-card-content {
        padding: 0.5em;
    }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    background: white;
}
.mat-form-field-wrapper {
    min-height: 36px;
    padding: 0;
}
//-----------------------input--------------------------

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
    border-width: 1px!important;
}

//---------------------------menu----------------------------
.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

//---------------------------dropdown-------------------------
.mat-select-value {
    position: relative;
    top: -2px;
}
.mat-select-arrow-wrapper {
    position: relative;
    top: -1px;
}

//----------------------------datepicker-----------------------

.mat-tab-label-content {
    width: max-content;
}

//----------------------------matMenu-----------------------

:host ::ng-deep {
  .mat-menu-content {
    padding: unset !important;
  }
}

//-------mat buttons -----------------------------
.mat-raised-button:hover {
    background-color: $primary-blue-500;
    color: $white;
}

.mat-raised-button.mat-primary[disabled]{
    background-color: $gray-300;
    color: $white;
 }

 .mat-stroked-button:hover {
    background-color: $primary-blue-100;
    color: $info;
}

.mat-stroked-button.mat-primary[disabled]{
    background-color: $white;
    color: $gray-600;
 }

.mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($primary) ;
}

.mat-radio-button {
    color: $gray-650;
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($primary, 200);
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($primary, 200);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
    background-color: mat.get-color-from-palette($primary, 200);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color:$gray-500;
}

.mat-tab-label {
    color: $disabled;

    &.mat-tab-label-active {
        color: $primary-blue-500;
    }

}

