$white: #ffffff;

// SEMANTIC COLORS
$error: #EB455A;
$error-light: #F7DCDF;
$success: #00D68F;
$warning: #FFAA00;
$info: #0095FF;
$disabled: #8F9BB3;

// PRIMARY
$primary-blue-100: #f2f6ff;
$primary-blue-200: #d9e4ff;
$primary-blue-300: #A6C1FF;
$primary-blue-400: #598BFF;
$primary-blue-500: #3366FF;
$primary-blue-600: #274BDB;
$primary-blue-700: #1A34B8;
$primary-blue-800: #102694;
$primary-blue-900: #091C7A;

// PRIMARY SHADOWS
$primary-blue-8: #eff3ff; // rgba($primary-blue-500, .08);
$primary-blue-16: #dee7ff; // rgba($primary-blue-500, .16);
$primary-blue-24: #cedaff; // rgba($primary-blue-500, .24);
$primary-blue-32: #beceff; // rgba($primary-blue-500, .32);
$primary-blue-40: #adc2ff; // rgba($primary-blue-500, .40);
$primary-blue-48: #9db6ff; // rgba($primary-blue-500, .48);

// BASIC GRAY
$gray-100: #FFFFFF;
$gray-200: #F7F9FC;
$gray-300: #EDF1F7;
$gray-400: #E4E9F2;
$gray-500: #C5CEE0;
$gray-600: #8F9BB3;
$gray-650: #737d95;
$gray-700: #2E3A59;
$gray-800: #222B45;
$gray-900: #192038;
$gray-1000: #151A30;
$gray-1100: #101426;

// BASIC GRAY SHADOWS
$gray-8: #f6f7f9; // rgba($gray-600, .08);
$gray-16: #edeff3; // rgba($gray-600, .16);
$gray-24: #e4e7ed; // rgba($gray-600, .24);
$gray-32: #dbdfe7; // rgba($gray-600, .32);
$gray-40: #d2d7e1; // rgba($gray-600, .40);
$gray-48: #c9cfdb; // rgba($gray-600, .48);
$modal-header: #F5F5F5;

// TEXT COLORS
$text-color: #101426;
$alternative-text-color: #ffffff;
$accent-color: $primary-blue-500;
$hint-color: $gray-600;
$disabled-color: $primary-blue-500;

$primary-blue-hover: #005DC7;
$separator-color: #9BB0D9;

$header-background: #E5E6E8;

